@mixin mediaS {
  @media (max-width: 799px) {
    @content;
  }
}

@mixin mediaM {
  @media (max-width: 1199px) and (min-width: 800px) {
    @content;
  }
}

@mixin mediaL {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin mediaMS {
  // M & S
  @media (max-width: 1199px) {
    @content;
  }
}
