/* 主色 主色紫 以及延展色，场景主按钮、UI控件 */
$purple-1: #31348a;
$purple-2: #5257d9;
$purple-3: #7175f5;
$purple-4: #9da0f5;
$purple-5: #d1d2f5;
$purple-6: #f1f1fd;
$purple-7: #e4e2ff;

/* 辅助色 辅色蓝、黄、红、绿 以及延展色，场景主背景，辅助按钮、icon等 */
$yellow-1: #695b45;
$yellow-2: #ae7f28;
$yellow-3: #f5a50a;
$yellow-4: #fbbb3b;
$yellow-5: #ffd749;
$yellow-6: #ffeeb1;

$blue-1: #0a3588;
$blue-2: #3773e5;
$blue-3: #70a0ff;
$blue-4: #bfd5ff;

$red-1: #a54435;
$red-2: #f45940;
$red-3: #ff745e;
$red-4: #ffb3a7;
$red-5: #ffd4ce;
$red-6: #ff634a;
$red-7: #f44e34;

$green-1: #376f3f;
$green-2: #40b04f;
$green-3: #60d36f;
$green-4: #8df29a;

/** 灰色 大部分的文字色、容器底色、容器描边、背景色等场景 */
$grey-1: #111111;
$grey-2: #333333;
$grey-3: #555555;
$grey-4: #777777;
$grey-5: #999999;
$grey-6: #cccccc;
$grey-7: #eaeaea;
$grey-8: #f2f2f2;
$grey-9: #cdcdcd;

$white: #fff;

/* UI 未定义的颜色 */
$grey-extra-1: #f7f7f7;

$white: #ffffff;
