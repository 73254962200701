@import './mediaQuery.scss';

@function rem($px) {
  @return remL($px);
}

@function remL($px) {
  @return ($px / 192) * 1rem;
}

@function remM($px) {
  @return ($px / 119.4) * 1rem;
}
/**
transform rem m form l
 */
@function tRemM($px) {
  @return remL($px) * 1.62;
}

@function remS($px) {
  @return ($px / 37.5) * 1rem;
}

/**
transform rem s form l
 */
@function tRemS($px) {
  @return remL($px) * 3.3485;
}

@mixin singleLineTextOverflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin multiLineTextOverflow($line: 3) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin borderRadius($normal: 10) {
  border-radius: rem($normal);
  @include mediaM {
    border-radius: remM($normal);
  }
  @include mediaS {
    border-radius: remS($normal);
  }
}
