@import './mixin';

/* 字号 */
$fz-1: 40;
$lh-1: 56;
$fz-2: 32;
$lh-2: 45;
$fz-3: 30;
$lh-3: 42;
$fz-4: 26;
$lh-4: 36;
$fz-5: 24;
$lh-5: 34;
$fz-6: 22;
$lh-6: 31;
$fz-7: 20;
$lh-7: 28;
$fz-8: 18;
$lh-8: 25;
$fz-9: 14;
$lh-9: 20;

/* 字重 */
$fw-bold: 600;
$fw-mediun: 500;
$fw-regular: 400;
$fw-light: 300;

@mixin text-h1 {
  font-size: rem($fz-1);
  line-height: rem($lh-1);
}

@mixin text-h2 {
  font-size: rem($fz-2);
  line-height: rem($lh-2);
}

@mixin text-h3 {
  font-size: rem($fz-3);
  line-height: rem($lh-3);
}

@mixin text-h4 {
  font-size: rem($fz-4);
  line-height: rem($lh-4);
}

@mixin text-h5 {
  font-size: rem($fz-5);
  line-height: rem($lh-5);
}

@mixin text-body-1 {
  font-size: rem($fz-6);
  line-height: rem($lh-6);
}

@mixin text-body-2 {
  font-size: rem($fz-7);
  line-height: rem($lh-7);
}

@mixin text-body-3 {
  font-size: rem($fz-8);
  line-height: rem($lh-8);
}

@mixin text-body-4 {
  font-size: rem($fz-9);
  line-height: rem($lh-9);
}
