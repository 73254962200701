@function vw($px) {
  @return ($px / 1920) * 100vw;
}

@function vh($px) {
  @return ($px / 1080) * 100vh;
}

@function rem($px) {
  @return ($px / 1920) * 10rem;
}

@import '../../styles/mixin';
@import '../../styles/typography';
@import '../../styles/colors';

/* Slider */
.slick-loading .slick-list {
  background: $grey-6 center center no-repeat;
}

/* Arrows */
.slick-prev,
.slick-next {
  display: block;
  position: absolute;
  top: 50%;
  z-index: 10;

  width: rem(30);
  height: rem(30);
  padding: 0;
  transform: translate(0, -50%);

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;

  justify-content: center;
  align-items: center;
  border-radius: 50%;

  background-size: 100% 100%;
  opacity: 0;
  transition: background-image 0.3s, opacity 0.3s;
}

.slick-prev {
  left: rem(16);
  background-image: url('./left.svg');
  &:hover {
    background-image: url('./left-hover.svg');
  }
}

.slick-next {
  right: rem(16);
  background-image: url('./right.svg');
  &:hover {
    background-image: url('./right-hover.svg');
  }
}
/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: 13px;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}
.slick-dots li {
  position: relative;

  display: inline-block;

  width: rem(10);
  height: rem(10);
  margin: 0 rem(5);
  padding: 0;

  cursor: pointer;
}
.slick-dots li button {
  position: absolute;
  padding: 0;
  font-size: 0;
  line-height: 0;

  display: block;

  width: 100%;
  height: 100%;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;

  background: url('./dot.svg') top left no-repeat;
  background-size: 100% auto;
  box-shadow: 0px rem(4) rem(4) rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}
.slick-dots li.slick-active button {
  background-image: url('./dot-active.svg');
  box-shadow: 0px rem(4) rem(10) rgba(0, 0, 0, 0.25);
}

.slick-slide {
  font-size: 0;
  line-height: 0;
}

.slick-slider:hover {
  .slick-prev,
  .slick-next {
    opacity: 1;
  }
}
