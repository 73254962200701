@function rem($px) {
  @return remL($px);
}

@function remL($px) {
  @return ($px / 192) * 1rem;
}

@function remM($px) {
  @return ($px / 119.4) * 1rem;
}

@function remS($px) {
  @return ($px / 37.5) * 1rem;
}
