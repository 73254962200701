@import './mixin';

.#{$prefix}-cursor {
  &-pointer {
    cursor: pointer;
  }
}

@mixin cursorPointer {
  cursor: url('https://m.xiguacity.cn/gutenberg/mouse/pointer.png') 12 0, pointer;
}

@mixin cursorDefault {
  cursor: url('https://m.xiguacity.cn/gutenberg/mouse/default.png') 7 4, auto;
}

@mixin cursorInput {
  cursor: url('https://m.xiguacity.cn/gutenberg/mouse/input.png') 7 4, auto;
}

@mixin cursorDisabled {
  cursor: url('https://m.xiguacity.cn/gutenberg/mouse/disabled.png') 7 4, auto;
}

@mixin cursorDragging {
  cursor: url('https://m.xiguacity.cn/gutenberg/mouse/dragging.png') 7 4, auto;
}

@mixin cursorDrag {
  cursor: url('https://m.xiguacity.cn/gutenberg/mouse/drag.png') 7 4, auto;
  &:active {
    @include cursorDragging;
  }
}
