@import './config';

@function vw($px) {
  @return ($px / 1920) * 100vw;
}

@function vh($px) {
  @return ($px / 1080) * 100vh;
}

@function rem($px) {
  @if (global-variable-exists(other-unit)) {
    @return ($px / 192) * 1px;
  }
  @return ($px / 192) * 1 + $unit;
}
