@function vw($px) {
  @return ($px / 1920) * 100vw;
}

@function vh($px) {
  @return ($px / 1080) * 100vh;
}

@function rem($px) {
  @return ($px / 1920) * 10rem;
}

@import '~@mondeo/melon-design/lib/styles/mixin.scss';
@import '~@mondeo/melon-design/lib/styles/cursor.scss';

$prefix: 'melon-materails-setting-btn';

.#{$prefix} {
  width: rem(84);
  height: rem(84);
  position: relative;
  @include cursorPointer;
  img {
    width: 100%;
    height: 100%;
  }
  &::before {
    content: '';
  }
  &-with-tip::before {
    content: '';
    background-image: url('./assets/tip.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right center;
    transition: background 1s cubic-bezier(0.08, 0.82, 0.17, 1);
    height: rem(90);
    width: rem(400);
    position: absolute;
    right: rem(92);
    top: rem(-6);
    z-index: 1;
  }
}
